
// Libraries
import * as React from 'react'
import { graphql } from 'gatsby'
import {useState} from 'react'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import FeaturedEvent from '../../components/featuredEvent'
import EventSearch from '../../components/eventSearch'
import EventList from '../../components/eventList'
import Button from '../../components/button'
import EventTiles from '../../components/eventTiles'
import HomeSlider from '../../components/homeSlider'

export default function EventsPage({ data }) {
	// Get hero event from GraphQL query
	const heroEvent = data?.heroEvent.edges[0].node.event

	// Get featured events from GraphQL query
	const featuredEvents = data?.featuredEvents.nodes

	// Initialise search query state
	const [searchQuery, setSearchQuery] = useState()

	// Search component callback
	const handleSearch = event => {
		// Set search query state
		setSearchQuery(event.target.value)
	}

	return (
		<Layout>
			<Seo title="Events" />
			{heroEvent
				? <>
					<FeaturedEvent event={heroEvent} />
					<EventSearch onSearch={handleSearch} />
					<EventTiles events={featuredEvents} />
					<HomeSlider events={featuredEvents} />
					<EventList searchQuery={searchQuery} />
				</>
				: <section className="temp-events-msg">
					<div className="container">
						<h1 className="temp-events-msg__heading">We will be uploading new events to the system from August. Please sign up to a Concerts for Carers account to be the first to know when events are available.</h1>
						<Button to="/register">Register</Button>
					</div>
				</section>}
		</Layout>
	)
}

export const query = graphql`
{
	heroEvent: allFeaturedEvent(filter: {type: {eq: "hero"}}, limit: 1) {
		edges {
			node {
				id
				event {
					id
					slug
					name
					starts
					image
					venue {
						name
					}
					type {
						name
					}
				}
			}
		}
	}

	featuredEvents: allFeaturedEvent(filter: {type: {eq: "featured"}}, limit: 3) {
		nodes {
			id
			event {
				id
				slug
				name
				starts
				image
				venue {
					name
				}
				type {
					name
				}
				artist {
					name
				}
			}
			image_gatsby {
				childImageSharp {
					gatsbyImageData(width: 1400)
				}
			}
		}
	}
}
`
