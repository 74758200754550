
// Libraries
import * as React from 'react'
import moment from 'moment'

// Components
import Hero from './hero'
import Button from './button'

const FeaturedEvent = ({ event }) => (
	<Hero featuredEvent bg="event" bgImage={event?.image}>
		<div className="container">
			{event?.artist?.name &&
				<h1>{event.artist.name}</h1>
			}

			{event?.name &&
				<h4>{event.name}</h4>
			}

			{event?.venue?.name &&
				<p>{event.venue.name}</p>
			}

			{event?.starts &&
				<p>{moment(event?.starts).format('MMMM D, Y')}</p>
			}

			<div>
				<Button to={`/events/${event?.slug}`}>See Event</Button>
			</div>
		</div>
	</Hero>
)

export default FeaturedEvent
