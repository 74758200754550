
// Libraries
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch  } from '@fortawesome/pro-regular-svg-icons'
import { navigate } from 'gatsby'
import {useState} from "react";

export default function EventSearch({ onSearch }) {
	// Initialise search query state
	const [searchQuery, setSearchQuery] = useState()

	const handleChange = (event) => {
		// Set search query state
		setSearchQuery(event?.target?.value)
		onSearch(event)
	}

	const handleSubmit = event => {
		event.preventDefault()

		// Navigate user to search page with the current search query
		return navigate('/events/search', {
			state: {searchQuery: searchQuery || ''}
		})
	}

	return (
		<form onSubmit={handleSubmit} className="event-search">
			<div className="container">
				<h5 className="event-search__heading">Search</h5>
				<div className="event-search-wrap">
					<input type="text" placeholder="What are you looking for?" className="event-search-wrap__input"
						   onChange={handleChange}/>
					<button className="event-search-wrap__submit" type="submit">
						<FontAwesomeIcon icon={faSearch} title="Search"/>
					</button>
				</div>
			</div>
		</form>
	)
}